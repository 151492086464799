import { PrimaryButton } from '@fluentui/react'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'

const useStyles = createUseStyles({
    button: {
        marginTop: 20,
    },
    message: {
        padding: [[30, 20]],
    },
    card: {
        marginTop: '20%',
    },
    centerCol: {
        textAlign: 'center',
    },
    title: {
        paddingBottom: 10,
        fontWeight: 'bold',
    },
})

export type MessagePageProps = {
    onButtonClick?: () => void
    title?: string
}

export const MessagePage: FC<MessagePageProps> = ({ onButtonClick, title, children }) => {
    const hasButton = onButtonClick !== undefined
    const hasTitle = title !== undefined
    const styles = useStyles()

    return (
        <div className="ms-Grid" dir="ltr">
            <div className={`ms-Grid-row ${styles.card}`}>
                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"></div>
                <div className={`ms-Grid-col ms-sm8 ms-md8 ms-lg8 ms-depth-8 ${styles.centerCol} ${styles.message}`}>
                    <div>
                        {hasTitle ? <div className={styles.title}>{title}</div> : null}
                        <div>{children}</div>
                    </div>
                    {hasButton ? (
                        <div className={styles.button}>
                            <PrimaryButton onClick={onButtonClick}>Fermer</PrimaryButton>
                        </div>
                    ) : null}
                </div>
                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"></div>
            </div>
        </div>
    )
}
