import { z } from 'zod'

const requirementSetSchema = z.enum([
    'WordApi',
    'ExcelApi',
    'DialogApi',
    'DocumentEvents',
    'CustomXmlParts',
    'File',
    'CompressedFile',
])

export const requirementSchema = z.union([z.tuple([requirementSetSchema]), z.tuple([requirementSetSchema, z.string()])])

export type Requirement = z.infer<typeof requirementSchema>

export const defaultRequirements: Requirement[] = [
    ['DocumentEvents'],
    ['DialogApi', '1.2'],
    ['File'],
    ['CompressedFile'],
]
