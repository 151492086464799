import { DocumentLink } from '../../document'
import { OfficeRequestChannel, createClient } from '../../messaging'
import { Requirement } from '../../requirements'
import { OfficeAPI } from '../host'
import { officeDialogAPI } from './schemas'

export type OfficeDialogAPI = Omit<OfficeAPI, 'getAccessToken'> & {
    close: () => Promise<void>
    getAccessToken: () => Promise<string>
}

export const getOfficeDialogAPI = (channel?: OfficeRequestChannel): OfficeDialogAPI => {
    const client = createClient(officeDialogAPI, channel ?? new OfficeRequestChannel())

    const getAccessToken = async (): Promise<string> =>
        client('getAccessToken', undefined).then(({ accessToken }) => accessToken)

    const getDocumentLink = async () => client('getDocumentLink', undefined).then(({ documentLink }) => documentLink)
    const setDocumentLink = async (documentLink: DocumentLink) => client('setDocumentLink', { documentLink })

    const close = async () => client('close', undefined)

    const getDocumentAsBase64 = async () => client('getDocumentAsBase64', undefined).then(({ data }) => data)

    const checkRequirements = async (requirements: Requirement[], silent?: boolean) =>
        client('checkRequirements', { requirements, silent }).then(({ requirementsMet }) => requirementsMet)

    return {
        getAccessToken,
        getDocumentAsBase64,
        getDocumentLink,
        setDocumentLink,
        close,
        checkRequirements,
    }
}
