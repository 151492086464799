export const handleAsyncResult = <T>(result: Office.AsyncResult<T>): T => {
    if (result.status === Office.AsyncResultStatus.Succeeded) {
        return result.value
    } else {
        throw result.error
    }
}

type OfficeAsyncCallback<R> = (result: Office.AsyncResult<R>) => void

type OfficeAsyncOptions = Office.AsyncContextOptions

interface RunAsync {
    <R, O extends OfficeAsyncOptions>(
        fn: (options: O, callback?: OfficeAsyncCallback<R> | undefined) => void,
        options?: O
    ): Promise<R>
    <R, O extends OfficeAsyncOptions, A0>(
        fn: (arg0: A0, options: O, callback?: OfficeAsyncCallback<R> | undefined) => void,
        arg0: A0,
        options?: O
    ): Promise<R>
    <R, O extends OfficeAsyncOptions, A0, A1>(
        fn: (arg0: A0, arg1: A1, options: O, callback?: OfficeAsyncCallback<R> | undefined) => void,
        arg0: A0,
        arg1: A1,
        options?: O
    ): Promise<R>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const runAsync: RunAsync = async <R>(fn: (...args: any[]) => void, ...args: unknown[]): Promise<R> => {
    const asyncResult = await new Promise<Office.AsyncResult<R>>(resolve => {
        fn(...args, (result: Office.AsyncResult<R>) => {
            resolve(result)
        })
    })
    return handleAsyncResult(asyncResult)
}
