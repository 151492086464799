import { decorator } from '..'

export const coalesce = <R, F extends () => Promise<R>>(fn: F): F => {
    let promise: Promise<R> | undefined

    const coalesced = async function (this: unknown) {
        if (promise !== undefined) {
            return promise
        }

        promise = fn.apply(this).finally(() => (promise = undefined))

        return promise
    }

    return coalesced as F
}

export const Coalesce = <R, T extends () => Promise<R>>(
    _target: unknown,
    _propertyKey: string,
    descriptor: TypedPropertyDescriptor<T>
): TypedPropertyDescriptor<T> => {
    return decorator.wrap(descriptor, orig => {
        const withCoalesce = coalesce(orig)
        return async function (this: unknown) {
            return withCoalesce.apply(this)
        } as T
    })
}
