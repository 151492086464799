import flatten from 'lodash/flatten'

import { documentReady } from '../env'
import { handleAsyncResult, runAsync } from '../run'
import { scheduler } from '../scheduler'

type ByteArray = number[]

const getDocumentData = async (): Promise<Uint8Array> => {
    await documentReady

    return scheduler.schedule(async () => {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const file: Office.File = await runAsync(Office.context.document.getFileAsync, Office.FileType.Compressed, {})
        const slices: Promise<ByteArray>[] = []

        for (let i = 0; i < file.sliceCount; i++) {
            slices.push(
                new Promise<ByteArray>((resolve, reject) => {
                    file.getSliceAsync(i, result => {
                        try {
                            const slice = handleAsyncResult(result)
                            resolve(slice.data)
                        } catch (e) {
                            reject(e)
                        }
                    })
                })
            )
        }

        return new Uint8Array(flatten(await Promise.all(slices)))
    })
}

export const getDocumentAsBase64 = async (): Promise<string> => {
    const documentAsByteArray = await getDocumentData()

    // We have to do this in a loop, which sets an upper-bound
    // on the number of arguments passed to the fromCharCode method
    // If there are too many args, it will hit a limit built into
    // browsers
    let strRepresentation = ''
    const step = 10000

    for (let i = 0; i < documentAsByteArray.length; i += step) {
        strRepresentation += String.fromCharCode(...documentAsByteArray.slice(i, i + step))
    }

    return btoa(strRepresentation)
}
