import { Separator, Stack } from '@fluentui/react'
import { useMemo, useRef } from 'react'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'

import { MainFrame } from '../'
import { useWindowSize } from './hooks'

const useStyles = createUseStyles({
    footerSeparator: {
        paddingBottom: 20,
    },
    wrapper: {
        height: '100%',
    },
})

type DialogFrameProps = {
    header: React.JSX.Element
    main: React.JSX.Element
    footer: React.JSX.Element
}

export const DialogFrame: FC<DialogFrameProps> = ({ header, main, footer }) => {
    const styles = useStyles()

    const headerRef = useRef<HTMLDivElement | null>(null)
    const footerRef = useRef<HTMLDivElement | null>(null)
    const windowSize = useWindowSize()

    // We need to dynamically determine the list height based on the header/footer
    // and window size
    const height = useMemo<string>(() => {
        const { height } = windowSize
        const headerEl = headerRef.current
        const footerEl = footerRef.current

        if (height === undefined || headerEl === null || footerEl === null) {
            return 'auto'
        }

        const headerHeight = headerEl.parentElement!.clientHeight
        const footerHeight = footerEl.parentElement!.clientHeight

        const h = height - headerHeight - footerHeight

        return `${h}px`
    }, [windowSize, headerRef, footerRef])

    const dialogMainStyle = useMemo(() => ({ height, overflow: 'scroll', position: 'relative' }) as const, [height])

    return (
        <MainFrame>
            <Stack className={styles.wrapper} verticalAlign="space-between">
                <Stack.Item>
                    <div ref={headerRef}>{header}</div>
                </Stack.Item>

                <Stack.Item>
                    <div style={dialogMainStyle} data-is-scrollable="true">
                        {main}
                    </div>
                </Stack.Item>

                <Stack.Item>
                    <Separator className={styles.footerSeparator} />
                    <div ref={footerRef}>{footer}</div>
                </Stack.Item>
            </Stack>
        </MainFrame>
    )
}
