import { logger } from '@publica/ui-common-logger'

import { OfficeApp } from './types'

declare global {
    // eslint-disable-next-line no-var
    var PublicaOfficeEnvironment: string | undefined
    // eslint-disable-next-line no-var
    var PublicaOfficeEnvironmentIsDialog: boolean | undefined
    // eslint-disable-next-line no-var
    var PublicaOfficeEnvironmentFeatures: Record<string, boolean>
}

export const getEnvironment = (): string => {
    const env = globalThis.PublicaOfficeEnvironment

    if (env === undefined) {
        throw new Error('Environment has not been set!')
    }

    return env
}

export const setEnvironment = <KnownFeatures extends string>(
    env: string,
    isDialog: boolean,
    features?: Record<KnownFeatures, boolean>,
    logLabels?: Record<string, string>
): void => {
    if (globalThis.PublicaOfficeEnvironment !== undefined) {
        throw new Error('Once set, the environment should not be changed!')
    }

    logger.component = env

    if (logLabels !== undefined) {
        logger.addLabels(logLabels)
    }

    // We add this asynchronously to ensure that the Office context is available
    void Office.onReady(() => {
        logger.addLabels({
            officeComponent: Office.context.host,
            officePlatform: Office.context.platform,
            officeVersion: Office.context?.diagnostics?.version,
        })
    })

    logger.logBanner()
    logger.log(`Setting environment to ${env}`)

    globalThis.PublicaOfficeEnvironment = env
    globalThis.PublicaOfficeEnvironmentIsDialog = isDialog
    globalThis.PublicaOfficeEnvironmentFeatures = {
        ...features,
    }
}

export const isFeatureActive = (feature: string): boolean =>
    globalThis.PublicaOfficeEnvironmentFeatures[feature] ?? false

export const isDialog = (): boolean => {
    const isDialog = globalThis.PublicaOfficeEnvironmentIsDialog

    if (isDialog === undefined) {
        throw new Error('Environment has not been set!')
    }

    return isDialog
}

export const isInOfficeApp = new Promise<boolean>(resolve => {
    void Office.onReady(({ host, platform }) => {
        const inOffice = host !== null && platform !== null
        logger.info(`In Office: ${inOffice}`)
        resolve(inOffice)
    })
})

// Global singleton promise that resolves when the initial document setup is complete
export const documentReady = new Promise<void>(resolve => {
    resolve(
        (async () => {
            const inOffice = await isInOfficeApp

            if (!inOffice) {
                return
            }

            logger.info('Document ready')
        })()
    )
})

export const officeHost = (): OfficeApp => {
    const host = Office.context.diagnostics.host

    switch (host) {
        case Office.HostType.Word:
            return 'Word'
        case Office.HostType.Excel:
            return 'Excel'
        default:
            throw new Error('Unsupported host')
    }
}
