/* istanbul ignore file */
import { z } from 'zod'

import { metadataPropertyValueSchema } from './metadata'

export const standardMetadataPropertiesSchema = z
    .object({
        operationId: z.union([z.string(), z.number().transform(val => val.toString())]),
        environment: z.string().default('development'),
    })
    .catchall(metadataPropertyValueSchema)

export type StandardMetadataProperties = z.infer<typeof standardMetadataPropertiesSchema>
