import { z } from 'zod'

import { requirementSchema } from '../../requirements'

const documentLinkSchema = z.object({
    operationId: z.string(),
    environment: z.string(),
})

export const officeDialogAPI = {
    getAccessToken: {
        request: z.void(),
        response: z.object({
            accessToken: z.string(),
        }),
    },
    getDocumentLink: {
        request: z.void(),
        response: z.object({
            documentLink: z.union([z.undefined(), documentLinkSchema]),
        }),
    },
    setDocumentLink: {
        request: z.object({
            documentLink: documentLinkSchema,
        }),
        response: z.void(),
    },
    getDocumentAsBase64: {
        request: z.void(),
        response: z.object({
            data: z.string(),
        }),
    },
    close: {
        request: z.void(),
        response: z.void(),
    },
    checkRequirements: {
        request: z.object({
            requirements: z.array(requirementSchema),
            silent: z.boolean().optional(),
        }),
        response: z.object({
            requirementsMet: z.boolean(),
        }),
    },
}
