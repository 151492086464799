import { logger } from '@publica/ui-common-logger'

import { documentReady } from '../env'
import { scheduler } from '../scheduler'
import { DocumentMetadata, readMetadata, writeMetadataProperties } from './metadata'

export type DocumentLink = DocumentMetadata['properties']

export const getDocumentLink = async (): Promise<DocumentLink | undefined> => {
    await documentReady

    const metadata = await scheduler.schedule(async () => readMetadata())

    if (metadata === undefined) {
        return undefined
    }

    return metadata.properties
}

export const setDocumentLink = async (link: DocumentLink): Promise<void> => {
    await documentReady

    const { operationId, environment } = link
    logger.info(`Linking document to operation ${operationId} in environment ${environment}`)

    await scheduler.schedule(async () => writeMetadataProperties(link))
}
