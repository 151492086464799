import mapValues from 'lodash/mapValues'
import { z } from 'zod'

import { assert } from '@publica/utils'

export type Method<Req extends z.ZodTypeAny = z.ZodTypeAny, Res extends z.ZodTypeAny = z.ZodTypeAny> = {
    request: Req
    response: Res
}

type JSONLiteral = string | number | boolean | null
type JSON = JSONLiteral | { [key: string]: JSON } | JSON[]

export type MethodCallPayload = JSON

export type MethodCall = {
    method: string
    payload?: JSON | undefined
}

export type MethodCallFn = (payload: MethodCall) => Promise<MethodCall>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RequestOptions = Record<string, any>

export abstract class RequestChannel<O extends RequestOptions = Record<string, never>> {
    abstract send(message: MethodCall, options?: O): Promise<MethodCall>
}

type ExtractRequestOptions<R extends RequestChannel> = R extends RequestChannel<infer O> ? O : never

export abstract class ResponseChannel {
    protected handlers: Record<string, MethodCallFn>

    constructor() {
        this.handlers = {}
    }

    setHandlers(handlers: Record<string, MethodCallFn>) {
        this.handlers = handlers
    }
}

export type Service<M extends string = string> = Record<M, Method<z.ZodTypeAny, z.ZodTypeAny>>

export type ServiceHandlers<S extends Service> = {
    [M in keyof S]: (request: z.infer<S[M]['request']>) => Promise<z.infer<S[M]['response']>>
}

export const createServer = <S extends Service>(
    service: S,
    handlers: ServiceHandlers<S>,
    channel: ResponseChannel
): void => {
    const wrappedHandlers = mapValues(handlers, (handler, key) => async ({ method, payload }: MethodCall) => {
        if (key !== method) {
            throw new Error('Internal method mismatch')
        }

        const schema = service[method]

        assert.defined(schema, `Unable to find schema for server method: ${method}`)

        const request = schema.request.parse(payload)
        return handler(request)
    })

    channel.setHandlers(wrappedHandlers)
}

export type ServiceClient<S extends Service, O extends RequestOptions> = <K extends keyof S>(
    method: K,
    request: z.infer<S[K]['request']>,
    options?: O
) => Promise<z.infer<S[K]['response']>>

export const createClient = <S extends Service, R extends RequestChannel>(
    service: S,
    channel: R
): ServiceClient<S, ExtractRequestOptions<R>> => {
    const client: ServiceClient<S, ExtractRequestOptions<R>> = async (method, request, options) => {
        const response = await channel.send({ method: method as string, payload: request }, options)
        const schema = service[method]

        assert.defined(schema, `Unable to find schema for client method: ${method.toString()}`)

        if (response.method !== method) {
            throw new Error('Internal method mismatch')
        }

        return schema.response.parse(response.payload)
    }

    return client
}
