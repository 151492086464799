import { logger } from '@publica/ui-common-logger'

import { runAsync } from './run'

const width = 50
const height = 60

class Dialog {
    private dialog: Office.Dialog | undefined
    private url: string
    private state: 'open' | 'closed' | 'ready'

    constructor(
        public readonly name: string,
        url?: string
    ) {
        this.name = name
        this.url = url ?? getDialogUrl(name)
        this.state = 'ready'
    }

    async display(createDialogServer: (dialog: Office.Dialog) => void) {
        if (this.state !== 'ready') {
            throw new Error('Dialog is already open, or has already been closed')
        }

        const { name, url } = this

        logger.debug('displaying dialog', { payload: { name, url } })

        this.dialog = await runAsync<Office.Dialog, Office.DialogOptions, string>(
            // eslint-disable-next-line @typescript-eslint/unbound-method
            Office.context.ui.displayDialogAsync,
            this.url,
            { width, height }
        )

        this.state = 'open'

        createDialogServer(this.dialog)

        this.dialog.addEventHandler(Office.EventType.DialogEventReceived, () => {
            logger.debug('dialog closed', { payload: { name, url } })

            this.state = 'closed'
            this.dialog = undefined
        })
    }
}

const dialogs: Record<string, Dialog> = {}

export const getDialog = (name: string): Dialog => {
    let dialog = dialogs[name]
    if (dialog === undefined) {
        dialog = new Dialog(name)
        dialogs[name] = dialog
    }
    return dialog
}

export const displayDialog = async (
    name: string,
    createDialogServer: (dialog: Office.Dialog) => void
): Promise<void> => {
    const dialog = getDialog(name)
    return dialog.display(createDialogServer)
}

const getDialogUrl = (name: string): string => {
    const html = `${name}.html`
    const url = new URL(html, window.location.href)
    return url.href
}
