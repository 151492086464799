import { z } from 'zod'

const metadataPropertyLiteralValueSchema = z.union([z.number(), z.string(), z.boolean(), z.null()])
type MetadataPropertyLiteralValue = z.infer<typeof metadataPropertyLiteralValueSchema>

export type MetadataPropertyValue =
    | MetadataPropertyLiteralValue
    | { [key: string]: MetadataPropertyValue }
    | MetadataPropertyValue[]

export const metadataPropertyValueSchema: z.ZodType<MetadataPropertyValue> = z.lazy(() =>
    z.union([
        metadataPropertyLiteralValueSchema,
        z.record(metadataPropertyValueSchema),
        z.array(metadataPropertyValueSchema),
    ])
)

export const metadataPropertiesSchema = z.record(metadataPropertyValueSchema)

export const metadataSchema = z.object({
    documentType: z.enum(['template', 'spreadsheet']),
    version: z.literal('1.0'),
    properties: metadataPropertiesSchema,
})

type MetadataProperties = z.infer<typeof metadataPropertiesSchema>

export type Metadata<P extends MetadataProperties = MetadataProperties> = Omit<
    z.infer<typeof metadataSchema>,
    'properties'
> & { properties: P }
