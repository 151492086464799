import { IModalStyleProps, IModalStyles, IOverlayProps, IStyleFunctionOrObject, Modal } from '@fluentui/react'
import color from 'color'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'

const useStyles = createUseStyles({
    modal: {
        backgroundColor: 'transparent',
        textAlign: 'center',
        boxShadow: 'none',
    },
    modalOverlay: {
        backgroundColor: color('white').fade(0.1).hex(),
    },
})

export type DialogModalProps = {
    isOpen: boolean
}

export const DialogModal: FC<DialogModalProps> = ({ isOpen, children }) => {
    const styles = useStyles()

    const modalStyle: IStyleFunctionOrObject<IModalStyleProps, IModalStyles> = useMemo(
        () => ({
            main: styles.modal,
        }),
        [styles.modal]
    )

    const modalOverlayStyle: IOverlayProps = useMemo(
        () => ({
            styles: {
                root: styles.modalOverlay,
            },
        }),
        [styles.modalOverlay]
    )

    return (
        <Modal isOpen={isOpen} isBlocking={true} styles={modalStyle} overlay={modalOverlayStyle}>
            {children}
        </Modal>
    )
}
