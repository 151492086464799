import { DefaultButton, IButtonProps, IStackTokens, PrimaryButton, Stack } from '@fluentui/react'
import { createUseStyles } from 'react-jss'

import { FC } from '@publica/ui-common-utils'
import { getOfficeDialogAPI } from '@publica/ui-office-utils'

const useStyles = createUseStyles({
    controls: {
        paddingTop: 25,
        paddingBottom: 20,
    },
})

type OnButtonClick = NonNullable<IButtonProps['onClick']>

type ControlButtonProps = {
    label?: string
    disabled?: boolean
    onClick?: OnButtonClick
}

type DialogControlsProps = {
    primary?: ControlButtonProps
    secondary?: ControlButtonProps
}

// These control the styling of items in Stack elements
export const dialogControlsTokens: IStackTokens = { childrenGap: 20, padding: '0 15px' }

const defaultPrimary: ControlButtonProps = {
    label: 'OK',
    disabled: true,
}

const defaultSecondard: ControlButtonProps = {
    label: 'Annuler',
    disabled: false,
    onClick: () => {
        void getOfficeDialogAPI().close()
    },
}

export const DialogControls: FC<DialogControlsProps> = props => {
    const styles = useStyles()

    const primary: ControlButtonProps = { ...defaultPrimary, ...props.primary }
    const secondary: ControlButtonProps = { ...defaultSecondard, ...props.secondary }

    return (
        <Stack
            className={styles.controls}
            horizontal={true}
            horizontalAlign="end"
            verticalAlign="center"
            tokens={dialogControlsTokens}
        >
            <PrimaryButton onClick={primary.onClick} disabled={primary.disabled}>
                {primary.label}
            </PrimaryButton>
            <DefaultButton onClick={secondary.onClick} disabled={secondary.disabled}>
                {secondary.label}
            </DefaultButton>
        </Stack>
    )
}
