import { SaxesParser, type SaxesTagNS } from 'saxes'
import { z } from 'zod'

import { type Metadata, metadataSchema } from './metadata'

export function parseMetadata(xml: string): Metadata
export function parseMetadata<Z extends z.AnyZodObject>(xml: string, propertiesSchema: Z): Metadata<z.output<Z>>
export function parseMetadata<Z extends z.AnyZodObject>(
    xml: string,
    propertiesSchema?: Z
): Metadata | Metadata<z.output<Z>> {
    const parser = new SaxesParser({ xmlns: true })
    const root = new RootNode()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stack: Node<any>[] = []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let currentNode: Node<any> = root

    parser.on('opentag', tag => {
        stack.push(currentNode)
        currentNode = currentNode.acceptTag(tag)
    })

    parser.on('closetag', () => {
        const previous = stack.pop()
        /* istanbul ignore next */
        if (previous === undefined) {
            throw Error('Inconsistent stack!')
        }
        currentNode = previous
    })

    parser.on('text', text => {
        currentNode.acceptText(text.trim())
    })

    parser.write(xml)

    const { properties, ...other } = metadataSchema.parse(root.toJSON().metadata)

    if (propertiesSchema !== undefined) {
        return { ...other, properties: propertiesSchema.parse(properties) }
    }

    return { properties, ...other }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
abstract class Node<C extends Node<any> = never> {
    acceptTag(tag: SaxesTagNS): C {
        throw new UnexpectedTagError(tag, this)
    }

    acceptText(_: string): void {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    abstract toJSON(): any
}

class UnexpectedTagError extends Error {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(tag: SaxesTagNS, parent: Node<any>) {
        const message = `Unexpected tag: ${tag.name} for ${parent.constructor.name}`
        super(message)

        Object.setPrototypeOf(this, UnexpectedTagError.prototype)
    }
}

class RootNode extends Node<MetadataNode> {
    metadata: MetadataNode | undefined

    acceptTag(tag: SaxesTagNS): MetadataNode {
        if (tag.local === 'metadata' && this.metadata === undefined) {
            this.metadata = new MetadataNode()
            return this.metadata
        }
        /* istanbul ignore next */
        return super.acceptTag(tag)
    }

    toJSON() {
        return {
            metadata: this.metadata?.toJSON(),
        }
    }
}

type MetadataNodeChildNodes = StringNode | PropertiesNode

class MetadataNode extends Node<MetadataNodeChildNodes> {
    documentType: StringNode | undefined
    version: StringNode | undefined
    properties: PropertiesNode | undefined

    acceptTag(tag: SaxesTagNS): MetadataNodeChildNodes {
        if (tag.local === 'documentType' && this.documentType === undefined) {
            this.documentType = new StringNode()
            return this.documentType
        }

        if (tag.local === 'version' && this.version === undefined) {
            this.version = new StringNode()
            return this.version
        }

        if (tag.local === 'properties' && this.properties === undefined) {
            this.properties = new PropertiesNode()
            return this.properties
        }

        return super.acceptTag(tag)
    }

    toJSON() {
        return {
            documentType: this.documentType?.toJSON(),
            version: this.version?.toJSON(),
            properties: this.properties?.toJSON(),
        }
    }
}

class PropertiesNode extends Node<PropertyNode> {
    properties: PropertyNode[]

    constructor() {
        super()
        this.properties = []
    }

    acceptTag(tag: SaxesTagNS): PropertyNode {
        if (tag.local === 'property') {
            const propertyNode = new PropertyNode()
            this.properties.push(propertyNode)
            return propertyNode
        }

        return super.acceptTag(tag)
    }

    toJSON() {
        return this.properties.reduce((record, property) => {
            const { key, value } = property.toJSON()

            if (key === undefined) {
                throw new Error(`Found property without a key`)
            }

            return {
                ...record,
                [key]: value,
            }
        }, {} as PropertyMapValue)
    }
}

type PropertyChildNodes = KeyNode | StringNode | IntegerNode | FloatNode | BooleanNode | MapNode | ArrayNode | NullNode

class PropertyValueNode extends Node<PropertyChildNodes> {
    type: 'string' | 'integer' | 'float' | 'boolean' | 'map' | 'array' | 'null' | undefined

    string: StringNode | undefined
    integer: IntegerNode | undefined
    float: FloatNode | undefined
    boolean: BooleanNode | undefined
    map: MapNode | undefined
    array: ArrayNode | undefined
    null: NullNode | undefined

    acceptTag(tag: SaxesTagNS): PropertyChildNodes {
        if (tag.local === 'string' && this.type === undefined) {
            this.string = new StringNode()
            this.type = 'string'
            return this.string
        }

        if (tag.local === 'boolean' && this.type === undefined) {
            this.boolean = new BooleanNode()
            this.type = 'boolean'
            return this.boolean
        }

        if (tag.local === 'float' && this.type === undefined) {
            this.float = new FloatNode()
            this.type = 'float'
            return this.float
        }

        if (tag.local === 'integer' && this.type === undefined) {
            this.integer = new IntegerNode()
            this.type = 'integer'
            return this.integer
        }

        if (tag.local === 'map' && this.type === undefined) {
            this.map = new MapNode()
            this.type = 'map'
            return this.map
        }

        if (tag.local === 'array' && this.type === undefined) {
            this.array = new ArrayNode()
            this.type = 'array'
            return this.array
        }

        if (tag.local === 'null' && this.type === undefined) {
            this.null = new NullNode()
            this.type = 'null'
            return this.null
        }

        return super.acceptTag(tag)
    }

    toJSON(): PropertyValue | undefined {
        if (this.type === undefined) {
            return undefined
        }

        return this[this.type]?.toJSON()
    }
}

class PropertyNode extends PropertyValueNode {
    key: KeyNode | undefined

    acceptTag(tag: SaxesTagNS): PropertyChildNodes {
        if (tag.local === 'key' && this.key === undefined) {
            this.key = new KeyNode()
            return this.key
        }

        if (this.type !== undefined) {
            throw new UnexpectedTagError(tag, this)
        }

        return super.acceptTag(tag)
    }

    toJSON(): { key: string | undefined; value: PropertyValue | undefined } {
        const key = this.key?.toJSON()
        const value = super.toJSON()

        return {
            key,
            value,
        }
    }
}

class KeyNode extends Node {
    key: string | undefined

    acceptText(text: string): void {
        this.key = text
    }

    toJSON() {
        return this.key
    }
}

abstract class LiteralNode<T extends PropertyLiteralValue> extends Node {
    value: T | undefined

    abstract parse(text: string): T

    acceptText(text: string): void {
        this.value = this.parse(text)
    }

    toJSON(): T | undefined {
        return this.value
    }
}

class StringNode extends LiteralNode<string> {
    parse(text: string): string {
        return text
    }
}

const truthValues = ['true', 'yes', 'y', '1'] as const
const booleanSchema = z
    .enum([...truthValues, 'false', 'no', 'n', '0'])
    .transform(val => truthValues.includes(val as (typeof truthValues)[number]))

class BooleanNode extends LiteralNode<boolean> {
    parse(text: string): boolean {
        return booleanSchema.parse(text)
    }
}

class IntegerNode extends LiteralNode<number> {
    parse(text: string): number {
        return parseInt(text)
    }
}

class FloatNode extends LiteralNode<number> {
    parse(text: string): number {
        return parseFloat(text)
    }
}

class NullNode extends LiteralNode<null> {
    /* istanbul ignore next */
    parse(_: string): null {
        return null
    }

    // We need to specify this, as the null node
    // typically doesn't have any descendants, so
    // the parse function is never called
    toJSON(): null | undefined {
        return null
    }
}

class MapNode extends PropertiesNode {
    acceptTag(tag: SaxesTagNS): PropertyNode {
        if (tag.local === 'item') {
            const propertyNode = new PropertyNode()
            this.properties.push(propertyNode)
            return propertyNode
        }

        /* istanbul ignore next */
        return super.acceptTag(tag)
    }
}

class ArrayNode extends Node<PropertyValueNode> {
    private readonly items: PropertyValueNode[]

    constructor() {
        super()
        this.items = []
    }

    acceptTag(tag: SaxesTagNS): PropertyValueNode {
        if (tag.local === 'item') {
            const propertyValueNode = new PropertyValueNode()
            this.items.push(propertyValueNode)
            return propertyValueNode
        }

        /* istanbul ignore next */
        return super.acceptTag(tag)
    }

    toJSON() {
        return this.items.map(item => item.toJSON())
    }
}

type PropertyLiteralValue = string | boolean | number | null
type PropertyValue = PropertyLiteralValue | PropertyMapValue | (PropertyValue | undefined)[]
type PropertyMapValue = { [key: string]: PropertyValue | undefined }
