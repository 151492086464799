import { DOMImplementation, XMLSerializer } from '@xmldom/xmldom'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import xmlBeautifier from 'xml-beautifier'

import { metadataNamespace } from './constants'
import type { Metadata, MetadataPropertyValue } from './metadata'

const ns = metadataNamespace

export const serializeMetadata = (sourceMetadata: Metadata): string => {
    const dom = new DOMImplementation()
    const document = dom.createDocument(null, null)

    const declaration = document.createProcessingInstruction('xml', 'version="1.0" encoding="UTF-8"')
    document.appendChild(declaration)

    const metadata = document.createElementNS(ns, 'fp:metadata')
    document.appendChild(metadata)

    const documentType = document.createElementNS(ns, 'fp:documentType')
    metadata.appendChild(documentType)

    documentType.appendChild(document.createTextNode(sourceMetadata.documentType))

    const version = document.createElementNS(ns, 'fp:version')
    metadata.appendChild(version)

    version.appendChild(document.createTextNode(sourceMetadata.version))

    const properties = document.createElementNS(ns, 'fp:properties')
    metadata.appendChild(properties)

    for (const propertyKey in sourceMetadata.properties) {
        const val = sourceMetadata.properties[propertyKey]!
        properties.appendChild(serializeProperty(document, val, 'property', propertyKey))
    }

    const serializer = new XMLSerializer()
    const xml = serializer.serializeToString(document)

    return xmlBeautifier(xml)
}

const serializeProperty = (
    document: XMLDocument,
    value: MetadataPropertyValue,
    tagName: 'property' | 'item',
    propertyKey?: string
): Element => {
    const property = document.createElementNS(ns, `fp:${tagName}`)

    if (propertyKey !== undefined) {
        const key = document.createElementNS(ns, 'fp:key')
        property.appendChild(key)
        key.appendChild(document.createTextNode(propertyKey))
    }

    let val: Element | undefined

    if (isString(value)) {
        val = document.createElementNS(ns, 'fp:string')
        val.appendChild(document.createTextNode(value))
    } else if (isBoolean(value)) {
        val = document.createElementNS(ns, 'fp:boolean')
        val.appendChild(document.createTextNode(value ? 'true' : 'false'))
    } else if (isNumber(value)) {
        const numberType = value % 1 === 0 ? 'integer' : 'float'
        val = document.createElementNS(ns, `fp:${numberType}`)
        val.appendChild(document.createTextNode(value.toString()))
    } else if (isArray(value)) {
        val = document.createElementNS(ns, 'fp:array')

        for (const item of value) {
            val.appendChild(serializeProperty(document, item, 'item'))
        }
    } else if (value === null) {
        val = document.createElementNS(ns, 'fp:null')
    } else if (isObject(value)) {
        val = document.createElementNS(ns, 'fp:map')

        for (const propertyKey in value) {
            const pValue = value[propertyKey]!
            val.appendChild(serializeProperty(document, pValue, 'item', propertyKey))
        }
    }

    /* istanbul ignore next */
    if (val === undefined) {
        throw new Error('Unable to serialize property value')
    }

    property.appendChild(val)

    return property
}
