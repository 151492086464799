import { InvalidDocumentError } from '../../errors'
import { CustomXmlManager } from './interface'

export class ExcelCustomXmlManager implements CustomXmlManager {
    async getUniqueCustomXmlForNamespace(ns: string): Promise<string | undefined> {
        return this.doWithCustomXmlForNamespace(ns, async (context, [part, ...rem]): Promise<string | undefined> => {
            if (part === undefined) {
                return undefined
            } else if (rem.length !== 0) {
                throw new InvalidDocumentError('Found more than one metadata document')
            }

            const xml = part.getXml()
            await context.sync()
            return xml.value
        })
    }

    async clearCustomXmlForNamespace(ns: string): Promise<void> {
        await this.doWithCustomXmlForNamespace(ns, async (context, parts): Promise<void> => {
            for (const part of parts) {
                part.delete()
            }
            await context.sync()
        })
    }

    async writeCustomXml(xml: string): Promise<void> {
        await Excel.run(async context => {
            context.workbook.customXmlParts.add(xml)
            await context.sync()
        })
    }

    private async doWithCustomXmlForNamespace<R>(
        ns: string,
        fn: (context: Excel.RequestContext, parts: Excel.CustomXmlPart[]) => Promise<R>
    ): Promise<R> {
        return Excel.run(async context => {
            const collection = context.workbook.customXmlParts.getByNamespace(ns)
            collection.load('items')
            await context.sync()

            return fn(context, collection.items)
        })
    }
}
