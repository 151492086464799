/* istanbul ignore file */
// Helper fn to create decorators
export const wrap = <R>(
    propertyDescriptor: TypedPropertyDescriptor<R>,
    fn: (original: R) => R
): TypedPropertyDescriptor<R> => {
    const orig = propertyDescriptor.value

    if (orig === undefined) {
        throw new Error('Property cannot be undefined')
    }

    propertyDescriptor.value = fn(orig)
    return propertyDescriptor
}
