import { InvalidDocumentError } from '../../errors'
import { runAsync } from '../../run'
import { CustomXmlManager } from './interface'

export class WordCustomXmlManager implements CustomXmlManager {
    async getUniqueCustomXmlForNamespace(ns: string): Promise<string | undefined> {
        const [part, ...rem] = await this.getCustomXmlPartsForNamespace(ns)

        if (part === undefined) {
            return undefined
        } else if (rem.length !== 0) {
            throw new InvalidDocumentError('Found more than one metadata document')
        }

        // eslint-disable-next-line @typescript-eslint/unbound-method
        return runAsync(part.getXmlAsync, {})
    }

    async writeCustomXml(xml: string): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        await runAsync(Office.context.document.customXmlParts.addAsync, xml, {})
    }

    async clearCustomXmlForNamespace(ns: string): Promise<void> {
        const parts = await this.getCustomXmlPartsForNamespace(ns)

        for (const part of parts) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            await runAsync(part.deleteAsync)
        }
    }

    private async getCustomXmlPartsForNamespace(ns: string): Promise<Office.CustomXmlPart[]> {
        const customXmlParts: Office.CustomXmlPart[] = await runAsync(
            // eslint-disable-next-line @typescript-eslint/unbound-method
            Office.context.document.customXmlParts.getByNamespaceAsync,
            ns
        )

        return customXmlParts
    }
}
