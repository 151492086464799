import { z } from 'zod'

import {
    Metadata,
    StandardMetadataProperties,
    metadataNamespace,
    parseMetadata,
    serializeMetadata,
    standardMetadataPropertiesSchema,
} from '@publica/document-metadata'

import { isInOfficeApp, officeHost } from '../env'
import { ExcelCustomXmlManager, WordCustomXmlManager } from './customXmlPart'
import { CustomXmlManager } from './customXmlPart/interface'

let customXmlManager: CustomXmlManager | undefined

const getCustomXmlManager = async (): Promise<CustomXmlManager> => {
    if (customXmlManager === undefined) {
        if (!(await isInOfficeApp)) {
            throw new Error('Attempting to create a CustomXML Manager outside of an Office app')
        }

        customXmlManager = officeHost() === 'Word' ? new WordCustomXmlManager() : new ExcelCustomXmlManager()
    }

    return customXmlManager
}

export type DocumentMetadata = Metadata<StandardMetadataProperties>

export async function readMetadata(): Promise<DocumentMetadata | undefined>
export async function readMetadata<Z extends typeof standardMetadataPropertiesSchema>(
    schema: Z
): Promise<Metadata<z.output<Z>> | undefined>
export async function readMetadata<Z extends typeof standardMetadataPropertiesSchema>(
    schema?: Z
): Promise<DocumentMetadata | Metadata<z.output<Z>> | undefined> {
    const manager = await getCustomXmlManager()
    const xml = await manager.getUniqueCustomXmlForNamespace(metadataNamespace)
    let meta: DocumentMetadata | undefined

    if (xml !== undefined) {
        meta = parseMetadata(xml, schema ?? standardMetadataPropertiesSchema)
    }

    return meta
}

const writeMetadata = async (metadata: Metadata): Promise<void> => {
    const xml = serializeMetadata(metadata)

    const manager = await getCustomXmlManager()

    await manager.clearCustomXmlForNamespace(metadataNamespace)
    await manager.writeCustomXml(xml)
}

export const writeMetadataProperties = async <P extends Metadata['properties']>(properties: P): Promise<void> => {
    const existingMeta = await readMetadata()
    let newMeta: Metadata

    if (existingMeta === undefined) {
        const documentType = ((): DocumentMetadata['documentType'] => {
            switch (officeHost()) {
                case 'Excel':
                    return 'spreadsheet'
                case 'Word':
                    return 'template'
            }
        })()

        newMeta = {
            documentType,
            version: '1.0',
            properties,
        }
    } else {
        newMeta = {
            ...existingMeta,
            properties: {
                ...existingMeta.properties,
                ...properties,
            },
        }
    }

    await writeMetadata(newMeta)
}
