import { logger } from '@publica/ui-common-logger'

import { DocumentLink, getDocumentLink, setDocumentLink } from '../document'
import { getDocumentAsBase64 } from '../document/file'
import { isDialog, isInOfficeApp } from '../env'
import { Requirement, defaultRequirements } from '../requirements'

export type OfficeAPI = {
    getAccessToken: (prompt: boolean) => Promise<string>
    getDocumentLink: () => Promise<DocumentLink | undefined>
    setDocumentLink: (link: DocumentLink) => Promise<void>
    getDocumentAsBase64: () => Promise<string>
    checkRequirements: (requirements: Requirement[], silent?: boolean) => Promise<boolean>
}

export const getOfficeHostAPI = (): OfficeAPI => ({
    getAccessToken: async (prompt: boolean) => getOfficeAccessToken(prompt),
    getDocumentLink,
    getDocumentAsBase64,
    setDocumentLink,
    checkRequirements,
})

const getOfficeAccessToken = async (prompt = false): Promise<string> => {
    if (isDialog()) {
        throw new Error('Cannot call getOfficeAccessToken from a dialog')
    }

    const authOptions = prompt
        ? {
              allowSignInPrompt: true,
              allowConsentPrompt: true,
          }
        : {}

    return OfficeRuntime.auth.getAccessToken(authOptions)
}

const checkRequirements = async (requirements: Requirement[], silent = false): Promise<boolean> => {
    await isInOfficeApp

    let meetsRequirements = true
    const allRequirements = [...defaultRequirements, ...requirements]

    for (const [name, version] of allRequirements) {
        if (!Office.context.requirements.isSetSupported(name, version)) {
            if (!silent) {
                let message = `Requirement not met: ${name}`
                message = version === undefined ? message : `${message} ${version}`
                logger.warn(message)
            }
            meetsRequirements = false
        }
    }

    return meetsRequirements
}
